import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/* Footer Start */}
      <div
        className="container-fluid bg-primary text-light footer wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5 px-lg-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Address
                <span />
              </p>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                123 Street, New York, USA
              </p>
              <p>
                <i className="fa fa-phone-alt me-3" />
                +012 345 67890
              </p>
              <p>
                <i className="fa fa-envelope me-3" />
                info@example.com
              </p>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Quick Link
                <span />
              </p>
              <Link className="btn btn-link" to="/">
                Home
              </Link>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/servives">
                Services
              </Link>
              <Link className="btn btn-link" to="/contact">
                Contact Us 
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Gallery
                <span />
              </p>
              <div className="row g-2">
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-1.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-2.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/img-4.jpg"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-3.png"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-2.png"
                    alt="Image"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid"
                    src="/static/img/gps-1.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <p className="section-title text-white h5 mb-4">
                Newsletter
                <span />
              </p>
              <p>
              Welcome to our GPS service website! Navigate with ease and precision using our cutting-edge GPS technology. Get accurate directions and real-time updates for a seamless travel experience. Explore now!
              </p>
              {/* <div className="position-relative w-100 mt-3">
            <input
              className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
              type="text"
              placeholder="Your Email"
              style={{ height: 48 }}
            />
            <button
              type="button"
              className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
            >
              <i className="fa fa-paper-plane text-primary fs-4" />
            </button>
          </div> */}
            </div>
          </div>
        </div>
        {/* <div className="container px-lg-5">
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            ©{" "}
            <Link className="border-bottom" to="#">
              Your Site Name
            </Link>
            , All Right Reserved.
            Designed By{" "}
            <Link className="border-bottom" to="https://htmlcodex.com">
              HTML Codex
            </Link>
            <br />
            <br />
            Distributed By Link{" "}
          
          </div>
          <div className="col-md-6 text-center text-md-end">
            <div className="footer-menu">
              <Link to="">Home</Link>
              <Link to="">Cookies</Link>
              <Link to="">Help</Link>
              <Link to="">FQAs</Link>
            </div>
          </div>
        </div>
      </div>
    </div> */}
      </div>
      {/* Footer End */}
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Feature from "../components/Feature";
import AboutComp from "../components/AboutComp";
import Facts from "../components/Facts";

const About = () => {
  return (
    <>
      {/* Navbar & Hero Start */}
      <div className="container-xxl position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            {/* <h1 className="m-0">GPSGo</h1> */}
            <img src="/static/img/logo.png" alt="Logo"/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto py-0">
              <Link to="/" className="nav-item nav-link">
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link active">
                About
              </Link>
              <Link to="/services" className="nav-item nav-link">
                Service
              </Link>
              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link
              to=""
              className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
            >
              Get Started
            </Link>
          </div>
        </nav>
        <div className="container-xxl py-5 bg-primary hero-header">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white animated slideInDown">About Us</h1>
                <hr className="bg-white mx-auto mt-0" style={{ width: 90 }} />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <Link className="text-white" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item text-white active"
                      aria-current="page"
                    >
                      About
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}
      <AboutComp />
      <Feature />
      <Facts/>
      <Footer />
    </>
  );
};

export default About;

import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Feature from "../components/Feature";
import AboutComp from "../components/AboutComp";
import Facts from "../components/Facts";
import Solutions from "../components/Solutions";
import Projects from "../components/Projects";
import Newsletter from "../components/Newsletter";

const Home = () => {
  return (
    <>
      {/* Navbar & Hero Start */}
      <div className="container-xxl position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            {/* <h1 className="m-0">GPSGo</h1> */}
            <img src="/static/img/logo.png" alt="Logo"/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto py-0">
              <Link to="index.html" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link">
                About
              </Link>
              <Link to="/services" className="nav-item nav-link">
                Service
              </Link>
              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link
              to="/"
              className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
            >
              Get Started
            </Link>
          </div>
        </nav>
        <div className="container-xxl bg-primary hero-header">
          <div className="container px-lg-5">
            <div className="row g-5 align-items-end">
              <div className="col-lg-6 text-center text-lg-start">
                <h1 className="text-white mb-4 animated slideInDown">
                  GPSGo for any kind <br /> of gps Problems
                </h1>
                <p className="text-white pb-3 animated slideInDown">
                Welcome to our GPS service website! Navigate with ease and precision using our cutting-edge GPS technology. Get accurate directions and real-time updates for a seamless travel experience. Explore now!
                </p>
                <Link
                  to="/about"
                  className="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"
                >
                  Read More
                </Link>
                <Link
                  to="/contact"
                  className="btn btn-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                >
                  Contact Us
                </Link>
              </div>
              <div className="col-lg-6 text-center text-lg-start">
                <img
                  className="img-fluid animated zoomIn"
                  src="/static/img/car-gps.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}

      <Feature />
      <AboutComp />
      <Facts />
      <Solutions />
      <Newsletter />
      <Projects />
      <Footer />
    </>
  );
};

export default Home;

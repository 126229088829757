import React from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  return <div>
     {/* Projects Start */}
  <div className="container-xxl">
    <div className="container px-lg-5">
      <div className="wow fadeInUp" data-wow-delay="0.1s">
        {/* <p className="section-title text-secondary justify-content-center">
          <span />
          
          <span />
        </p> */}
        {/* <h1 className="text-center mb-5">Recently Completed Projects</h1> */}
      </div>
      <div className="row g-4 portfolio-container">
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/gps-1.png"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/gps-1.png"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/gps-2.png"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/gps-2.png"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/gps-3.png"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/gps-3.png"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/img-2.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/img-2.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/img-1.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/img-1.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="/static/img/img-4.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="/static/img/img-4.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            {/* <div className="bg-light p-4">
              <p className="text-primary fw-medium mb-2">UI / UX Design</p>
              <h5 className="lh-base mb-0">
                Digital Agency Website Design And Development
              </h5>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Projects End */}
  </div>;
};

export default Projects;

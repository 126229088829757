import React from "react";

const Solutions = () => {
  return <div>
     {/* Service Start */}
  <div className="container-xxl py-5">
    <div className="container py-5 px-lg-5">
      <div className="wow fadeInUp" data-wow-delay="0.1s">
        <p className="section-title text-secondary justify-content-center">
          <span />
          Our Services
          <span />
        </p>
        <h1 className="text-center mb-5">What Solutions We Provide</h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-solid fa-upload fa-2x" />
            </div>
            <h5 className="mb-3">Quick Update</h5>
            <p className="m-0">
            Get quick updates on the latest news, events, and trends. Stay informed with our concise and timely updates delivered straight to your device. Stay in the know effortlessly.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-solid fa-map-pin fa-2x" />
            </div>
            <h5 className="mb-3">Accurate Location</h5>
            <p className="m-0">
            Discover the power of accurate location services. Find your way with precision and confidence, whether for navigation, or personal tracking. Experience accuracy at its finest.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-solid fa-laptop fa-2x" />
            </div>
            <h5 className="mb-3">Seamless Tracking</h5>
            <p className="m-0">
            Stay informed and in control with our advanced tracking solutions, providing real-time updates and precise monitoring for your needs. Explore seamless tracking today
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-mail-bulk fa-2x" />
            </div>
            <h5 className="mb-3">Email Notification</h5>
            <p className="m-0">
            Never miss an important email again. Stay on top of your inbox with our reliable email notification system, delivering instant alerts and updates straight to your device. Stay connected effortlessly.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fa fa-thumbs-up fa-2x" />
            </div>
            <h5 className="mb-3">Mostly Liked</h5>
            <p className="m-0">
            Discover our "Mostly Liked" collection, featuring the products and services that have garnered widespread acclaim and positive feedback from our satisfied customers. Explore what's loved the most by our community.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex flex-column text-center rounded">
            <div className="service-icon flex-shrink-0">
              <i className="fab fa-android fa-2x" />
            </div>
            <h5 className="mb-3">Android & Apple Apps Available</h5>
            <p className="m-0">
            Download our convenient Android and Apple apps to access our services on the go. Experience seamless functionality and stay connected with our user-friendly mobile applications. Available for both Android and Apple devices.
            </p>
            <a className="btn btn-square" href="">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}
  </div>;
};

export default Solutions;
